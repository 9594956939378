import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import uniqid from 'uniqid';
import Case from 'case';

import { Box } from 'grommet';

import { CurrentDateContext } from '@Components/Context';
import { withProductAuth } from '@Components/Layout';
import {
  Seo, AppButton, Spinning, UnderConstruction,
} from '@Components/Control';
import { AuthNavWrapper } from '@Components/Navigation';
import { ProductFormSectionWrapper } from '@Components/Partial/Product/NewSearch';

import {
  initiateResultsUploadConfigRequest,
  clearResultsUploadConfigRequest,
  submitResultsFileUploadRequest,
} from '@Actions';
import { paths } from '@Components/configs';

import DynamicFormRenderer from '../NewSearch/DynamicFormRenderer';


const FormContents = ({
  small,
  loading,
  underMaintenance,
  authPagesConfig,
  formInputs,
  formValues,
  handleFormValues,
  today,
  minStartDate,
  searchTypeKey,
  resetForm,
  submitEnabled,
  handleFormSubmit,
}) => {
  if (underMaintenance) {
    return (
      <Box flex align="center" justify="center" background="white">
        <UnderConstruction
          imageUrl={authPagesConfig.constructionImage}
          buttonHighlight={authPagesConfig.buttonHighlight}
          header="Under Maintenance"
          message="System currently undergoing maintenance. Please check back later."
        />
      </Box>
    );
  }

  if (loading) {
    return (
      <Box flex align="center" justify="center" background="white">
        <Spinning size="large" color={authPagesConfig.buttonHighlight} />
      </Box>
    );
  }

  // TODO: make it so if there's only one present in options it's automatically selected

  return (
    <Box flex direction="column" gap="2rem" pad={small ? '1.5rem' : '2rem'}>
      <Box gap="2rem" width="100%" direction="column">
        <ProductFormSectionWrapper
          small={small}
          sectionBg={authPagesConfig.altComponentBg}
          sectionTitle="Required fields"
          titleColor={authPagesConfig.primaryText}
          borderColor={authPagesConfig.navBorder}
        >
          {Array.isArray(formInputs) && formInputs?.length > 0 && formInputs.map((dt) => (
            <DynamicFormRenderer
              autoSelectSingleRadio
              key={dt.key}
              resetKey={formValues.resetKey}
              searchTypeKey={searchTypeKey}
              fieldData={dt}
              small={small}
              handleFormValues={(value, key) => handleFormValues(value, key)}
              formValues={formValues.searchFieldValues}
              authPagesConfig={authPagesConfig}
              today={today}
              minStartDate={minStartDate}
            />
          ))}
        </ProductFormSectionWrapper>
        <Box direction="row" gap="1rem" justify="start" pad={{ left: '2rem' }}>
          <AppButton
            width="6.5rem"
            onClick={() => resetForm()}
            level="dynamicLarge"
            color={authPagesConfig.buttonHighlight}
            fontWeight={600}
            label="Cancel"
          />
          <AppButton
            overrideHover
            disabled={!submitEnabled}
            onClick={() => (submitEnabled ? handleFormSubmit() : null)}
            level="dynamicLarge"
            color="white"
            bgColor={authPagesConfig.buttonHighlight}
            fontWeight={600}
            label="Upload results"
          />
        </Box>
      </Box>
    </Box>
  );
};

FormContents.propTypes = {
  small: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  underMaintenance: PropTypes.bool.isRequired,
  authPagesConfig: PropTypes.shape({
    pageBg: PropTypes.string.isRequired,
    altComponentBg: PropTypes.string.isRequired,
    navBorder: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    selectButtonBg: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    iconHighlightColor: PropTypes.string.isRequired,
    incrementText: PropTypes.string.isRequired,
    decrementText: PropTypes.string.isRequired,
    disableSearchFeatures: PropTypes.bool.isRequired,
    constructionImage: PropTypes.string.isRequired,
  }),
  formInputs: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    order: PropTypes.number.isRequired,
    config: PropTypes.objectOf(PropTypes.any),
    required: PropTypes.bool,
    fieldName: PropTypes.string.isRequired,
    fieldType: PropTypes.string.isRequired,
    selectable: PropTypes.bool,
    description: PropTypes.string,
  }).isRequired),
  formValues: PropTypes.shape({
    searchFieldValues: PropTypes.objectOf(PropTypes.any),
    resetKey: PropTypes.string,
  }),
  handleFormValues: PropTypes.func.isRequired,
  today: PropTypes.instanceOf(Date),
  minStartDate: PropTypes.string,
  searchTypeKey: PropTypes.string,
  resetForm: PropTypes.func.isRequired,
  submitEnabled: PropTypes.bool,
  handleFormSubmit: PropTypes.func.isRequired,
};

const ResultsUploadPage = ({
  small,
  location,
  loading,
  submitLoading,
  fetchResultsUploadConfig,
  clearResultsUploadConfig,
  submitResults,
  resultsFormConfig = null,
  authPagesConfig = null,
  customReports = null,
  cyclopsConfig = null,
}) => {
  const [formValues, setFormValues] = React.useState({
    searchFieldValues: {}, resetKey: uniqid(),
  });
  const [searchTypeKey, setSearchTypeKey] = React.useState(null);
  const { today } = React.useContext(CurrentDateContext);
  const underMaintenance = authPagesConfig && authPagesConfig.disableSearchFeatures;

  React.useEffect(() => {
    fetchResultsUploadConfig();

    return () => clearResultsUploadConfig();
  }, []);

  React.useEffect(() => {
    if (resultsFormConfig && formValues.searchFieldValues.category) {
      const useKey = Case.camel(formValues.searchFieldValues.category);
      setSearchTypeKey(useKey);
    }
  }, [formValues.searchFieldValues]);

  const handleFormValues = (value, key) => {
    const updatedValues = { ...formValues.searchFieldValues };

    if (!value || value?.length === 0) {
      delete updatedValues[key];
      setFormValues({ ...formValues, searchFieldValues: updatedValues });
      return;
    }

    updatedValues[key] = value;
    setFormValues({ ...formValues, searchFieldValues: updatedValues });
  };

  const resetForm = () => setFormValues({ searchFieldValues: {}, resetKey: uniqid() });

  const requiredKeys = Array.isArray(resultsFormConfig)
    && resultsFormConfig.filter(({ required }) => required).map(({ key }) => key);
  const submitEnabled = requiredKeys && requiredKeys.every((key) => (
    key in formValues.searchFieldValues
    && (formValues.searchFieldValues[key].length > 0
    || formValues.searchFieldValues[key] !== null)
  ));

  /* eslint-disable camelcase */
  const handleFormSubmit = () => {
    submitResults(
      { search_field_values: formValues.searchFieldValues },
      paths.productResultsUpload,
      () => resetForm(),
    );
  };
  /* eslint-enable camelcase */

  return (
    <AuthNavWrapper
      small={small}
      location={location}
      authPagesConfig={authPagesConfig}
      customReports={customReports}
      cyclopsConfig={cyclopsConfig}
      bannerProps={{
        title: 'Upload infringing results',
        subTitle: 'Blacklight',
        textColor: 'white',
      }}
    >
      <Seo />
      <FormContents
        small={small}
        key="results-upload"
        loading={loading || submitLoading}
        authPagesConfig={authPagesConfig}
        underMaintenance={underMaintenance}
        formInputs={resultsFormConfig}
        formValues={formValues}
        handleFormValues={handleFormValues}
        searchTypeKey={searchTypeKey}
        today={today}
        minStartDate={null}
        resetForm={resetForm}
        submitEnabled={submitEnabled}
        handleFormSubmit={handleFormSubmit}
      />
    </AuthNavWrapper>
  );
};

function mapStateToProps(state) {
  return {
    resultsFormConfig: state.productResults.resultsUploadConfig,
    loading: state.fetchLoader.dataLoading,
    submitLoading: state.fetchLoader.submissionLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchResultsUploadConfig: initiateResultsUploadConfigRequest,
    clearResultsUploadConfig: clearResultsUploadConfigRequest,
    submitResults: submitResultsFileUploadRequest,
  }, dispatch);
}

ResultsUploadPage.propTypes = {
  small: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  submitLoading: PropTypes.bool.isRequired,
  fetchResultsUploadConfig: PropTypes.func.isRequired,
  clearResultsUploadConfig: PropTypes.func.isRequired,
  submitResults: PropTypes.func.isRequired,
  resultsFormConfig: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    order: PropTypes.number.isRequired,
    config: PropTypes.objectOf(PropTypes.any),
    required: PropTypes.bool,
    fieldName: PropTypes.string.isRequired,
    fieldType: PropTypes.string.isRequired,
    selectable: PropTypes.bool,
    description: PropTypes.string,
  }).isRequired),
  authPagesConfig: PropTypes.shape({
    pageBg: PropTypes.string.isRequired,
    altComponentBg: PropTypes.string.isRequired,
    navBorder: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    selectButtonBg: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    iconHighlightColor: PropTypes.string.isRequired,
    incrementText: PropTypes.string.isRequired,
    decrementText: PropTypes.string.isRequired,
    disableSearchFeatures: PropTypes.bool.isRequired,
  }),
  customReports: PropTypes.objectOf(PropTypes.any),
  cyclopsConfig: PropTypes.arrayOf(PropTypes.any),
};

export default connect(mapStateToProps, mapDispatchToProps)(withProductAuth(ResultsUploadPage));
