import {
  VERTICAL_TIERS_LOADED,
  VERTICAL_TIERS_CLEAR,
  CUSTOMER_SUBSCRIPTION_LOADED,
  CUSTOMER_SUBSCRIPTION_CLEAR,
} from './actionTypes';

import * as api from './apiHelper';
import { initiateRedirectRequest } from './redirectActions';
import {
  setFetchLoaderLoading,
  setFetchLoaderReset,
  setSubmissionLoaderLoading,
  setSubmissionLoaderReset,
} from './fetchLoaderActions';
import { arriveNotice, arriveCustomNotice } from './noticeActions';


export function verticalTiersLoaded(verticalTiers) {
  return {
    type: VERTICAL_TIERS_LOADED,
    payload: { verticalTiers },
  };
}

export function verticalTiersClear() {
  return { type: VERTICAL_TIERS_CLEAR };
}

export function customerSubscriptionLoaded(subscription) {
  return {
    type: CUSTOMER_SUBSCRIPTION_LOADED,
    payload: { subscription },
  };
}

export function customerSubscriptionClear() {
  return { type: CUSTOMER_SUBSCRIPTION_CLEAR };
}

// API
async function getVerticalTiersApi(args) {
  const response = await api.get('subscriptions', args, null, 'v4');
  return response.json();
}

async function getCustomerSubscriptionApi(companyId, args, token) {
  const response = await api.get(`subscriptions/${companyId}`, args, token, 'v4');
  return response.json();
}

async function createSubscriptionApi(body, dispatch) {
  const response = await api.customErrorPost('subscriptions', body, null, 'v4');

  if (!response.ok) {
    const json = await response.json();

    if (json?.errors) {
      const customError = api.extractErrorMessage(json.errors);
      dispatch(arriveCustomNotice(customError));

      throw customError;
    } else {
      const formattedMessage = api.formatJsonError(json.error);
      const error = new Error(formattedMessage);
      dispatch(arriveNotice(error.message));

      throw error;
    }
  }

  return response.json();
}

async function updateSubscriptionApi(body, token, dispatch) {
  const response = await api.customErrorPost('subscriptions/update_subscription', body, token, 'v4');

  if (!response.ok) {
    const json = await response.json();

    if (json?.errors) {
      const customError = api.extractErrorMessage(json.errors);
      dispatch(arriveCustomNotice(customError));

      throw customError;
    } else {
      const formattedMessage = api.formatJsonError(json.error);
      const error = new Error(formattedMessage);
      dispatch(arriveNotice(error.message));

      throw error;
    }
  }

  return response.json();
}

async function updatePaymentMethodApi(body, token) {
  const response = await api.patch('subscriptions/update_payment_method', body, token, 'v4');
  return response.json();
}

// Public components actions
export function initiateCreateSubscriptionRequest(requestBody, stripeInfo, redirectPath) {
  return async (dispatch, getState) => {
    try {
      const { fetchLoader: { submissionLoading } } = getState();
      if (!submissionLoading) dispatch(setSubmissionLoaderLoading());

      const result = await stripeInfo.stripe.createToken(stripeInfo.card);

      if (result.error) {
        dispatch(setSubmissionLoaderReset());
        throw new Error(result.error.message);
      } else {
        const body = { token: result.token.id, ...requestBody };
        await createSubscriptionApi(body, dispatch);

        dispatch(arriveNotice('Signup successful! Check your email for next steps', 'ok'));
        dispatch(initiateRedirectRequest(null, redirectPath, true));
        dispatch(setSubmissionLoaderReset());
      }
    } catch (e) {
      dispatch(setSubmissionLoaderReset());
    }
  };
}

export function initiateUpdateSubscriptionRequest(requestBody, redirectPath, isRestore = false) {
  return async (dispatch, getState) => {
    try {
      const { auth: { token }, fetchLoader: { submissionLoading } } = getState();
      if (!submissionLoading) dispatch(setSubmissionLoaderLoading());

      const isCancel = requestBody.cancel_sub ?? false;

      await updateSubscriptionApi(requestBody, token, dispatch);

      let notice = isCancel ? 'Subscription cancelled successfully' : 'Subscription updated successfully!';

      if (isRestore) {
        notice = 'Subscription restored successfully!';
      }

      dispatch(arriveNotice(notice, 'ok'));
      dispatch(initiateRedirectRequest(null, redirectPath, true));
      dispatch(setSubmissionLoaderReset());
    } catch (e) {
      dispatch(setSubmissionLoaderReset());
    }
  };
}

export function initiateUpdatePaymentMethodRequest(stripeInfo, redirectPath) {
  return async (dispatch, getState) => {
    try {
      const { auth: { token }, fetchLoader: { submissionLoading } } = getState();
      if (!submissionLoading) dispatch(setSubmissionLoaderLoading());

      const result = await stripeInfo.stripe.createToken(stripeInfo.card);

      if (result.error) {
        dispatch(setSubmissionLoaderReset());
        throw new Error(result.error.message);
      } else {
        const body = { token: result.token.id };
        await updatePaymentMethodApi(body, token);

        dispatch(arriveNotice('Payment method updated successfully!', 'ok'));
        dispatch(setSubmissionLoaderReset());
        dispatch(initiateRedirectRequest(null, redirectPath, true));
      }
    } catch (e) {
      dispatch(setSubmissionLoaderReset());
      dispatch(arriveNotice(e.message));
    }
  };
}

export function clearVerticalTiersRequest() {
  return (dispatch) => {
    dispatch(verticalTiersClear());
  };
}

export function fetchVerticalTiersRequest(verticalUid) {
  return async (dispatch, getState) => {
    try {
      dispatch(verticalTiersClear());

      const { fetchLoader: { dataLoading } } = getState();
      if (!dataLoading) dispatch(setFetchLoaderLoading());

      const args = { vertical: verticalUid };
      const response = await getVerticalTiersApi(args);

      dispatch(verticalTiersLoaded(response));
      dispatch(setFetchLoaderReset());
    } catch (e) {
      if (e.name !== 'AbortError') {
        dispatch(setFetchLoaderReset());
        dispatch(arriveNotice(e.message));
      }
    }
  };
}

export function clearCustomerSubscriptionRequest() {
  return (dispatch) => {
    dispatch(customerSubscriptionClear());
  };
}

export function fetchCustomerSubscriptionRequest(companyId, args) {
  return async (dispatch, getState) => {
    try {
      dispatch(customerSubscriptionClear());

      const { auth: { token }, fetchLoader: { dataLoading } } = getState();
      if (!dataLoading) dispatch(setFetchLoaderLoading());

      const response = await getCustomerSubscriptionApi(companyId, args, token);
      dispatch(customerSubscriptionLoaded(response));
      dispatch(setFetchLoaderReset());
    } catch (e) {
      if (e.name !== 'AbortError') {
        dispatch(setFetchLoaderReset());
        dispatch(arriveNotice(e.message));
      }
    }
  };
}
