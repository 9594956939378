import React from 'react';
import PropTypes from 'prop-types';
import {
  Navigate, useSearchParams, useParams,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import {
  Box, Text, Layer,
} from 'grommet';

import {
  Spinning, AppButton,
} from '@Components/Control';
import { TermsOfUseModal } from '@Components/Modal';
import SubmissionLayout, { StyledSubmissionInput, StyledSubmissionField } from '@Components/Layout/Submission';
import {
  PaymentFormPartial,
  ProductSelectorPartial,
  PlatformSelectorPartial,
} from '@Components/Partial/Payment';
import { ProductFormCheckBoxInput } from '@Components/Partial/Product/NewSearch';
import { CurrentDateContext } from '@Components/Context';
import { paths } from '@Components/configs';
import {
  initiateCreateSubscriptionRequest,
  fetchVerticalTiersRequest,
  clearVerticalTiersRequest,
} from '@Actions';


const StyledLayer = styled(Layer)`background: transparent;`;

const SignupContent = ({
  small,
  loading,
  submitLoading,
  currentVertical,
  verticalTiers = null,
  createSignupRequest,
  preselectedTier = null,
  config: {
    bgColor,
    bgImage,
    textColor,
    highlight,
    selectButtonBg,
  },
}) => {
  /* eslint-disable camelcase */
  const [requestBody, setRequestBody] = React.useState({
    company_name: '',
    first_name: '',
    last_name: '',
    email: '',
    vertical: currentVertical,
    tier: '',
    stripe_price_id: '',
    terms_agreed: false,
    platforms: [],
  });
  const [selectedTier, setSelectedTier] = React.useState(null);
  const [showForm, setShowForm] = React.useState(false);
  const [showTermsModal, setShowTermsModal] = React.useState(false);

  const { today } = React.useContext(CurrentDateContext);
  const currentYear = today.getUTCFullYear();

  React.useEffect(() => {
    if (preselectedTier && verticalTiers) {
      const activeTier = verticalTiers.find((tier) => tier.metadata.uid === preselectedTier);

      if (activeTier) {
        setSelectedTier(activeTier);
        setRequestBody({
          ...requestBody,
          tier: activeTier.metadata.uid,
          stripe_price_id: activeTier.priceId,
        });
      }
    }
  }, [preselectedTier, verticalTiers]);

  React.useEffect(() => {
    let usePlatforms = [];

    if (selectedTier) {
      if (selectedTier.metadata.defaultPlatforms === 'all') {
        usePlatforms = selectedTier.platforms;
      }

      setRequestBody({
        ...requestBody,
        tier: selectedTier.metadata.uid,
        stripe_price_id: selectedTier.priceId,
        platforms: usePlatforms,
      });
    }
  }, [selectedTier]);

  const submitDisabled = !requestBody.company_name || !requestBody.first_name
    || !requestBody.last_name || !requestBody.email || !requestBody.tier
    || !requestBody.stripe_price_id || !requestBody.terms_agreed;
  const showPlatformSelect = selectedTier && selectedTier.platforms.length > 1 && selectedTier.metadata.defaultPlatforms !== 'all';
  const continueDisabled = !selectedTier || (showPlatformSelect && !requestBody.platforms.length);

  const renderContent = () => {
    if (showForm) {
      return (
        <Box direction="column">
          <StyledSubmissionField required name="companyName" htmlFor="companyName" type="text">
            <StyledSubmissionInput
              plain
              name="companyName"
              type="text"
              placeholder="Company name"
              highlight={highlight}
              onChange={(e) => setRequestBody({ ...requestBody, company_name: e.target.value })}
              value={requestBody.company_name}
            />
          </StyledSubmissionField>
          <StyledSubmissionField required name="firstName" htmlFor="firstName" type="text">
            <StyledSubmissionInput
              plain
              name="firstName"
              type="text"
              placeholder="First name"
              highlight={highlight}
              onChange={(e) => setRequestBody({ ...requestBody, first_name: e.target.value })}
              value={requestBody.first_name}
            />
          </StyledSubmissionField>
          <StyledSubmissionField required name="lastName" htmlFor="lastName" type="text">
            <StyledSubmissionInput
              plain
              name="lastName"
              type="text"
              placeholder="Last name"
              highlight={highlight}
              onChange={(e) => setRequestBody({ ...requestBody, last_name: e.target.value })}
              value={requestBody.last_name}
            />
          </StyledSubmissionField>
          <StyledSubmissionField required name="email" htmlFor="email" type="email">
            <StyledSubmissionInput
              plain
              name="email"
              type="email"
              placeholder="Email"
              highlight={highlight}
              onChange={(e) => setRequestBody({ ...requestBody, email: e.target.value })}
              value={requestBody.email}
            />
          </StyledSubmissionField>
          <Box direction="row" gap="1rem" align="center" pad={{ bottom: '1rem' }}>
            <Box width="fit-content">
              <ProductFormCheckBoxInput
                small={small}
                key="terms-checkbox"
                bgColor="white"
                borderColor="#D0D0D0"
                checkmarkColor={textColor}
                name="terms-checkbox"
                focusHighlight={highlight}
                value={requestBody.terms_agreed}
                handleFormValues={(val) => setRequestBody({ ...requestBody, terms_agreed: val })}
              />
            </Box>
            <Text size="1rem" color={textColor}>
              I have read and agree to the
              {' '}
              <Text
                size="1rem"
                color={highlight}
                onClick={() => setShowTermsModal(true)}
                style={{ cursor: 'pointer' }}
              >
                Terms of Use
              </Text>
            </Text>
          </Box>
          <PaymentFormPartial
            requestBody={requestBody}
            checkOutLoading={loading}
            checkout={createSignupRequest}
            submitMessage="Sign up"
            redirectPath={paths.login}
            buttonHighlight={highlight}
            submitDisabled={submitDisabled || submitLoading}
          />
          {showTermsModal && (
            <TermsOfUseModal
              toggle={() => setShowTermsModal(!showTermsModal)}
              isSmall={small}
              currentYear={currentYear}
              background="white"
              textColor={textColor}
            />
          )}
        </Box>
      );
    }

    return (
      <Box direction="column" gap="1rem" width={small ? '100%' : '30rem'}>
        <Text size="1rem" color={textColor} textAlign="center">
          Select a plan and platforms to start protecting your content
        </Text>
        <ProductSelectorPartial
          data={verticalTiers}
          selectedTier={selectedTier}
          setSelectedTier={setSelectedTier}
          preselectedTier={preselectedTier}
          highlight={highlight}
          selectButtonBg={selectButtonBg}
          textColor={textColor}
          loading={loading}
        />
        {showPlatformSelect && (
          <PlatformSelectorPartial
            selectedTierKey={selectedTier?.metadata?.uid}
            small={small}
            platforms={selectedTier.platforms}
            textColor={textColor}
            buttonHighlight={highlight}
            selectedPlatforms={requestBody.platforms}
            setSelectedPlatforms={(platforms) => setRequestBody({ ...requestBody, platforms })}
            limit={parseInt(selectedTier?.metadata?.platformCount, 10)}
          />
        )}
        <AppButton
          overrideHover
          onClick={() => setShowForm(true)}
          level="authSubmit"
          disabled={continueDisabled}
          bgColor={highlight}
          color="white"
          label="Continue to details"
          alignSelf="center"
        />
      </Box>
    );
  };

  return (
    <Box>
      {submitLoading && (
        <StyledLayer
          responsive={false}
          animate={false}
          onEsc={() => null}
          onClickOutside={() => null}
        >
          <Box
            align="center"
            justify="center"
            direction="column"
            pad="1rem"
            gap="1rem"
            background="white"
            width="15rem"
            round="10px"
          >
            <Spinning size="large" color={highlight} />
            <Text size="1rem" color={textColor}>
              Creating subscription...
            </Text>
          </Box>
        </StyledLayer>
      )}
      <SubmissionLayout
        withBackButton={showForm}
        backButtonClick={() => setShowForm(false)}
        seoConfig={null}
        title="Sign up"
        bgColor={bgColor}
        bgImage={bgImage}
        textColor={textColor}
        small={small}
        customWidth={showForm ? null : 'fit-content'}
        bottomPad={showForm ? '0' : '3rem'}
      >
        <Box direction="column">
          {renderContent()}
        </Box>
      </SubmissionLayout>
    </Box>
  );
  /* eslint-enable camelcase */
};

SignupContent.propTypes = {
  small: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  submitLoading: PropTypes.bool.isRequired,
  currentVertical: PropTypes.string.isRequired,
  createSignupRequest: PropTypes.func.isRequired,
  verticalTiers: PropTypes.arrayOf(PropTypes.shape({
    stripeId: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      defaultPlatforms: PropTypes.string.isRequired,
      delisting: PropTypes.string,
      infringementUpload: PropTypes.string.isRequired,
      manualReview: PropTypes.string.isRequired,
      platformCount: PropTypes.string.isRequired,
      searchLimit: PropTypes.string.isRequired,
      takedowns: PropTypes.string.isRequired,
      uid: PropTypes.string.isRequired,
      verticalUid: PropTypes.string.isRequired,
      order: PropTypes.string.isRequired,
      displayPrice: PropTypes.string.isRequired,
      displayPlatforms: PropTypes.string.isRequired,
    }).isRequired,
    priceId: PropTypes.string.isRequired,
    platforms: PropTypes.arrayOf(PropTypes.string.isRequired),
  })),
  preselectedTier: PropTypes.string,
  config: PropTypes.shape({
    bgColor: PropTypes.string.isRequired,
    bgImage: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
    highlight: PropTypes.string.isRequired,
    selectButtonBg: PropTypes.string.isRequired,
  }).isRequired,
};

const SignupPage = ({
  small,
  loading,
  submitLoading,
  createSignupRequest,
  fetchVerticalTiers,
  clearVerticalTiers,
  config,
  verticalTiers = null,
}) => {
  const { id: currentVertical } = useParams();
  const [searchParams] = useSearchParams();
  const preselectedTier = searchParams.get('tier', null);

  React.useEffect(() => {
    if (currentVertical) fetchVerticalTiers(currentVertical);

    return () => clearVerticalTiers();
  }, [currentVertical]);

  if (currentVertical && process.env.ENABLE_SIGNUP) {
    return (
      <SignupContent
        small={small}
        loading={loading}
        submitLoading={submitLoading}
        currentVertical={currentVertical}
        verticalTiers={verticalTiers}
        createSignupRequest={createSignupRequest}
        preselectedTier={preselectedTier}
        config={config}
      />
    );
  }

  return <Navigate to={paths.root} />;
};

function mapStateToProps(state) {
  return {
    loading: state.fetchLoader.dataLoading,
    submitLoading: state.fetchLoader.submissionLoading,
    verticalTiers: state.payment.verticalTiers,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    createSignupRequest: initiateCreateSubscriptionRequest,
    fetchVerticalTiers: fetchVerticalTiersRequest,
    clearVerticalTiers: clearVerticalTiersRequest,
  }, dispatch);
}

SignupPage.propTypes = {
  small: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  submitLoading: PropTypes.bool.isRequired,
  createSignupRequest: PropTypes.func.isRequired,
  fetchVerticalTiers: PropTypes.func.isRequired,
  clearVerticalTiers: PropTypes.func.isRequired,
  verticalTiers: PropTypes.arrayOf(PropTypes.any),
  config: PropTypes.shape({
    bgColor: PropTypes.string.isRequired,
    bgImage: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
    highlight: PropTypes.string.isRequired,
    selectButtonBg: PropTypes.string.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignupPage);
