import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Box, Text, Button,
} from 'grommet';

import { Spinning } from '@Components/Control';


const StyledProductSelectButton = styled(Button)`
  display: flex;
  justify-content: start;
  align-items: center;
  border-radius: 5px;
  width: 100%;
  border: ${({ borderColor }) => (borderColor ? `1px solid ${borderColor}` : '1px solid #D0D0D0')};
  color: ${({ primaryText }) => primaryText};
  background-color: white;

  &:hover {
    border: ${({ buttonHighlight }) => `1px solid ${buttonHighlight}`};
    color: ${({ buttonHighlight }) => buttonHighlight};
    background-color: ${({ buttonBg }) => buttonBg};
  }

  ${({ selected, buttonHighlight, buttonBg }) => selected && `
    border: ${`1px solid ${buttonHighlight}`};
    color: ${buttonHighlight};
    background-color: ${buttonBg};
  `}
`;

const ProductSelector = ({
  data,
  selectedTier,
  setSelectedTier,
  preselectedTier = null,
  activeTierUid = null,
  highlight,
  selectButtonBg,
  textColor,
  loading,
}) => {
  if (loading) {
    return (
      <Box direction="column" gap="1rem" align="center" justify="center" height="100%">
        <Spinning size="large" color={highlight} />
        <Text size="1rem" color={textColor}>
          Loading products...
        </Text>
      </Box>
    );
  }

  if (!loading && (!data || data?.length === 0)) {
    return (
      <Box direction="column" gap="1rem" align="center" justify="center" height="100%" pad={{ vertical: '1.5rem' }}>
        <Text size="1rem" color={textColor}>
          No products available
        </Text>
      </Box>
    );
  }

  const renderDisplayRow = (title, value) => {
    if (!value) return null;

    return (
      <Text size="1rem" color={textColor} weight={400}>
        {title}
        {' '}
        {value}
      </Text>
    );
  };

  const dataCopy = data && [...data];
  const orderedData = dataCopy?.sort((a, b) => (
    parseInt(a.metadata.order, 10) - parseInt(b.metadata.order, 10)
  ));

  return (
    <Box direction="column" gap="1rem">
      {orderedData?.map((tier) => (
        <StyledProductSelectButton
          plain
          key={tier.metadata.uid}
          buttonBg={selectButtonBg}
          buttonHighlight={highlight}
          onClick={() => setSelectedTier(tier)}
          selected={selectedTier?.metadata.uid === tier.metadata.uid}
        >
          <Box direction="column" gap="0.5rem" pad="1rem" width="100%">
            <Box direction="row" justify="between" align="center">
              <Text size="1.1rem" color={textColor} weight={600}>
                {tier.name}
                {preselectedTier && !activeTierUid && preselectedTier === tier.metadata.uid && (
                  <Text size="0.85rem" color="#038BFC" weight={400}>
                    {' '}
                    (Recommended)
                  </Text>
                )}
                {activeTierUid && activeTierUid === tier.metadata.uid && (
                  <Text size="0.85rem" color="#038BFC" weight={400}>
                    {' '}
                    (Current)
                  </Text>
                )}
              </Text>
              <Text size="1rem" color={textColor} weight={500}>
                {tier.metadata.displayPrice}
              </Text>
            </Box>
            <Text size="1rem" color={textColor} weight={500}>
              {tier.description}
            </Text>
            {renderDisplayRow('Titles: up to', tier.metadata.searchLimit)}
            {renderDisplayRow('Infringement upload:', tier.metadata.infringementUpload)}
            {renderDisplayRow('Platforms:', tier.metadata.displayPlatforms)}
            {renderDisplayRow('Delisting:', tier.metadata.delisting)}
            {renderDisplayRow('Takedowns:', tier.metadata.takedowns)}
          </Box>
        </StyledProductSelectButton>
      ))}
    </Box>
  );
};

ProductSelector.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    stripeId: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      defaultPlatforms: PropTypes.string.isRequired,
      infringementUpload: PropTypes.string.isRequired,
      platformCount: PropTypes.string.isRequired,
      searchLimit: PropTypes.string.isRequired,
      takedowns: PropTypes.string.isRequired,
      uid: PropTypes.string.isRequired,
      verticalUid: PropTypes.string.isRequired,
      order: PropTypes.string.isRequired,
      displayPrice: PropTypes.string.isRequired,
      displayPlatforms: PropTypes.string.isRequired,
      delisting: PropTypes.string,
    }).isRequired,
    priceId: PropTypes.string.isRequired,
  })),
  selectedTier: PropTypes.shape({
    stripeId: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      defaultPlatforms: PropTypes.string.isRequired,
      infringementUpload: PropTypes.string.isRequired,
      platformCount: PropTypes.string.isRequired,
      searchLimit: PropTypes.string.isRequired,
      takedowns: PropTypes.string.isRequired,
      uid: PropTypes.string.isRequired,
      verticalUid: PropTypes.string.isRequired,
      order: PropTypes.string.isRequired,
      displayPrice: PropTypes.string.isRequired,
      displayPlatforms: PropTypes.string.isRequired,
      delisting: PropTypes.string,
    }).isRequired,
    priceId: PropTypes.string.isRequired,
  }),
  setSelectedTier: PropTypes.func.isRequired,
  preselectedTier: PropTypes.string,
  activeTierUid: PropTypes.string,
  highlight: PropTypes.string.isRequired,
  selectButtonBg: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ProductSelector;
