import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import {
  Box, Text, Layer,
} from 'grommet';
import { FormPrevious } from 'grommet-icons';

import {
  AppAnchor, AppButton, Spinning,
} from '@Components/Control';
import { withProductAuth } from '@Components/Layout';
import { AuthNavWrapper } from '@Components/Navigation';
import {
  ProductSelectorPartial,
  PaymentFormPartial,
  PlatformSelectorPartial,
} from '@Components/Partial/Payment';
import { ProductFormSingleTextInput, ProductFormCheckBoxInput } from '@Components/Partial/Product/NewSearch';
import {
  fetchCustomerSubscriptionRequest,
  clearCustomerSubscriptionRequest,
  initiateUpdateSubscriptionRequest,
  initiateUpdatePaymentMethodRequest,
  fetchVerticalTiersRequest,
  clearVerticalTiersRequest,
} from '@Actions';
import { paths } from '@Components/configs';

import { SectionContainer } from './AccountPage';
import { StyledStatusBadge, StyledText } from './StyledAccountPage';


const StyledLayer = styled(Layer)`background: transparent;`;

const ManageSubscriptionPage = ({
  small,
  location,
  companyId,
  fetchSubscription,
  clearSubscription,
  fetchVerticalTiers,
  clearVerticalTiers,
  updateSubscription,
  updatePaymentMethod,
  loading,
  submitLoading,
  verticalTiers = null,
  subscriptionData = null,
  authPagesConfig = null,
  customReports = null,
  cyclopsConfig = null,
}) => {
  const { id: currentVertical } = useParams();
  const navigate = useNavigate();

  if (!currentVertical) navigate(paths.account);

  const [selectedTier, setSelectedTier] = React.useState(null);
  /* eslint-disable camelcase */
  const [updateRequestBody, setUpdateRequestBody] = React.useState({
    tier: null,
    sub_uid: null,
    stripe_price_id: null,
    cancel_sub: false,
    platforms: [],
  });
  const [cancelRequestBody, setCancelRequestBody] = React.useState({
    sub_uid: null,
    cancel_sub: true,
    cancel_reason: '',
  });
  const [acceptCancellation, setAcceptCancellation] = React.useState(false);
  const [acceptUpdate, setAcceptUpdate] = React.useState(false);

  React.useEffect(() => {
    fetchSubscription(companyId);
    fetchVerticalTiers(currentVertical);

    return () => {
      clearSubscription();
      clearVerticalTiers();
    };
  }, []);

  React.useEffect(() => {
    if (subscriptionData) {
      setUpdateRequestBody((prev) => ({
        ...prev,
        sub_uid: subscriptionData.uid,
      }));

      setCancelRequestBody((prev) => ({
        ...prev,
        sub_uid: subscriptionData.uid,
      }));
    }

    if (verticalTiers && !selectedTier && subscriptionData) {
      setSelectedTier(
        verticalTiers?.find((tier) => tier.metadata.uid === subscriptionData.tierUid),
      );
    }
  }, [subscriptionData, verticalTiers]);

  React.useEffect(() => {
    let usePlatforms = [];

    if (selectedTier) {
      if (selectedTier.metadata.defaultPlatforms === 'all') {
        usePlatforms = selectedTier.platforms;
      }

      setUpdateRequestBody({
        ...updateRequestBody,
        tier: selectedTier.metadata.uid,
        stripe_price_id: selectedTier.priceId,
        platforms: usePlatforms,
      });
    }
  }, [selectedTier]);

  const {
    primaryText, buttonHighlight, focusHighlight, selectButtonBg,
  } = authPagesConfig;

  const dateOptions = { month: 'short', day: '2-digit', year: 'numeric' };
  const formatDate = (date) => (date ? new Date(date * 1000).toLocaleDateString('en-US', dateOptions) : null);

  const activeSubTier = subscriptionData?.tierUid;
  const cardData = subscriptionData?.cardData;
  const cardDisplay = cardData && `${cardData.brand} ending in ${cardData.last4}, expires ${cardData.expMonth}/${cardData.expYear}`;
  const periodEnd = subscriptionData && formatDate(subscriptionData.currentPeriodEnd);
  const canceledDate = formatDate(subscriptionData?.canceledDate);
  const willCancelDate = formatDate(subscriptionData?.willCancelDate);
  const canceledMessage = canceledDate && willCancelDate && `Canceled on ${canceledDate}, valid through ${willCancelDate}`;
  const isCanceled = canceledMessage ?? false;
  const updateDisabled = !selectedTier || selectedTier?.metadata?.uid === activeSubTier
    || submitLoading || updateRequestBody.tier === null
    || updateRequestBody.stripe_price_id === null || updateRequestBody.sub_uid === null
    || !updateRequestBody.platforms.length || !acceptUpdate;
  const showPlatformSelect = selectedTier && selectedTier.platforms.length > 1 && selectedTier.metadata.defaultPlatforms !== 'all'
    && (activeSubTier !== selectedTier.metadata.uid);

  let cancelMessage = 'I wish to end my subscription at the end of the current pay period.';
  let buttonLabel = 'Cancel subscription';

  if (isCanceled) {
    cancelMessage = 'I wish to reactivate my subscription.';
    buttonLabel = 'Reactivate subscription';
  }

  const handleCancelRequest = () => {
    if (isCanceled) {
      const restoreBody = {
        tier: activeSubTier,
        sub_uid: subscriptionData?.uid,
        cancel_sub: false,
      };

      updateSubscription(restoreBody, paths.account, true);
    } else {
      updateSubscription(cancelRequestBody, paths.account);
    }
  };

  return (
    <AuthNavWrapper
      small={small}
      location={location}
      authPagesConfig={authPagesConfig}
      customReports={customReports}
      cyclopsConfig={cyclopsConfig}
      bannerProps={{
        title: 'Account information',
        subTitle: 'Manage subscription',
        textColor: 'white',
      }}
    >
      <Box flex direction="column" background="#F4F4F4" pad={small ? '2rem' : '3rem'}>
        {submitLoading && (
          <StyledLayer
            responsive={false}
            animate={false}
            onEsc={() => null}
            onClickOutside={() => null}
          >
            <Box
              align="center"
              justify="center"
              direction="column"
              pad="1rem"
              gap="1rem"
              background="white"
              width="15rem"
              round="10px"
            >
              <Spinning size="large" color={buttonHighlight} />
              <Text size="1rem" color={primaryText}>
                Updating subscription...
              </Text>
            </Box>
          </StyledLayer>
        )}
        <Box pad={{ bottom: '2rem' }}>
          <AppAnchor path={paths.account}>
            <Box direction="row" align="center" gap="0.15rem">
              <FormPrevious size="0.85rem" color="#038BFC" />
              <Text size="0.85rem" color="#038BFC">Back to account</Text>
            </Box>
          </AppAnchor>
        </Box>
        <SectionContainer
          small={small}
          sectionTitle="Update product tier"
          customWidth={small ? '60%' : '30%'}
          textColor={primaryText}
          margin={{ bottom: '2rem' }}
        >
          <Box direction="column" gap="1rem">
            <Box direction="column" gap="1rem" width={small ? '100%' : '90%'}>
              <StyledText size="1rem" color={primaryText}>
                Select a product tier to update your subscription.
                You will recieve an imediate charge for the new tier and be prorated for
                the remaining time on your current subscription. You will immediately
                gain access to the new product features, platforms, and limits.
              </StyledText>
              {periodEnd && (
                <Text size="1rem" color={primaryText}>
                  Your current pay period ends on
                  {' '}
                  {periodEnd}
                </Text>
              )}
            </Box>
            <Box direction="column" gap="1rem" width={small ? '100%' : '60%'}>
              <ProductSelectorPartial
                data={verticalTiers}
                selectedTier={selectedTier}
                setSelectedTier={setSelectedTier}
                activeTierUid={activeSubTier}
                highlight={buttonHighlight}
                selectButtonBg={selectButtonBg}
                textColor={primaryText}
                loading={loading}
              />
              {showPlatformSelect && (
                <PlatformSelectorPartial
                  selectedTierKey={selectedTier?.metadata?.uid}
                  small={small}
                  platforms={selectedTier.platforms}
                  textColor={primaryText}
                  buttonHighlight={buttonHighlight}
                  selectedPlatforms={updateRequestBody.platforms}
                  setSelectedPlatforms={(platforms) => (
                    setUpdateRequestBody({ ...updateRequestBody, platforms })
                  )}
                  limit={parseInt(selectedTier?.metadata?.platformCount, 10)}
                />
              )}
              <Box direction="row" gap="1rem" align="center">
                <Box width="fit-content">
                  <ProductFormCheckBoxInput
                    small={small}
                    key="accept-update-checkbox"
                    bgColor="white"
                    borderColor="#D0D0D0"
                    checkmarkColor={primaryText}
                    name="accept-update-checkbox"
                    focusHighlight={focusHighlight}
                    value={acceptUpdate}
                    handleFormValues={(val) => setAcceptUpdate(val)}
                  />
                </Box>
                <Text size="1rem" color={primaryText}>
                  I wish to update my product tier to the selection above.
                </Text>
              </Box>
              <AppButton
                overrideHover
                onClick={() => updateSubscription(updateRequestBody, paths.account)}
                level="authSubmit"
                disabled={updateDisabled}
                bgColor={buttonHighlight}
                color="white"
                label="Update subscription"
                alignSelf="center"
              />
            </Box>
          </Box>
        </SectionContainer>
        <SectionContainer
          small={small}
          sectionTitle="Update payment method"
          customWidth={small ? '60%' : '30%'}
          textColor={primaryText}
          margin={{ bottom: '2rem' }}
        >
          <Box direction="column" gap="1rem">
            <Box direction="column" gap="1rem" width={small ? '100%' : '90%'}>
              <Text size="1rem" color={primaryText} weight={500}>
                Current payment method:
                {' '}
                {cardDisplay}
              </Text>
              <StyledText size="1rem" color={primaryText}>
                Update your payment method by entering your new card details.
                This card will be charged at the end of your current pay period on
                {' '}
                {periodEnd}
                .
              </StyledText>
            </Box>
            <Box width={small ? '100%' : '60%'} pad={{ top: '0.5rem' }}>
              <PaymentFormPartial
                updateOnly
                checkOutLoading={false}
                checkout={updatePaymentMethod}
                redirectPath={paths.account}
                submitMessage="Update payment method"
                buttonHighlight={buttonHighlight}
                submitDisabled={false}
              />
            </Box>
          </Box>
        </SectionContainer>
        <SectionContainer
          small={small}
          sectionTitle="Cancel subscription"
          customWidth={small ? '60%' : '30%'}
          textColor={primaryText}
          margin={{ bottom: '2rem' }}
        >
          <Box direction="column" gap="1rem">
            <Box direction="column" gap="1rem" width={small ? '100%' : '90%'}>
              {(canceledMessage && isCanceled) ? (
                <Box direction="column" gap="1rem">
                  <StyledStatusBadge background="#FCF8EF">
                    <Text size={small ? '1rem' : '1.1rem'} color="#AB5741" weight={600}>
                      {canceledMessage}
                    </Text>
                  </StyledStatusBadge>
                  <StyledText size="1rem" color={primaryText}>
                    You can reactivate your subscription any time prior
                    to the end of your current pay period on
                    {' '}
                    {periodEnd}
                    . You will be charged normally at that time and
                    retain all product access.
                  </StyledText>
                </Box>
              ) : (
                <StyledText size="1rem" color={primaryText}>
                  We&apos;re sorry to see you go! Please leave a comment to let us
                  know why you&apos;re cancelling your subscription.
                  You will continue to have full product access until the
                  end of your current pay period on
                  {' '}
                  {periodEnd}
                  .
                </StyledText>
              )}
            </Box>
            <Box direction="column" gap="1rem" width={small ? '100%' : '60%'}>
              {!isCanceled && (
                <ProductFormSingleTextInput
                  placeholder="Enter your reason for cancellation (optional)"
                  value={cancelRequestBody.cancel_reason}
                  focusHighlight={focusHighlight}
                  handleFormValues={(val) => setCancelRequestBody((prev) => ({
                    ...prev,
                    cancel_reason: val,
                  }))}
                />
              )}
              <Box direction="row" gap="1rem" align="center" pad={{ bottom: '1rem' }}>
                <Box width="fit-content">
                  <ProductFormCheckBoxInput
                    small={small}
                    key="accept-checkbox"
                    bgColor="white"
                    borderColor="#D0D0D0"
                    checkmarkColor={primaryText}
                    name="accept-checkbox"
                    focusHighlight={focusHighlight}
                    value={acceptCancellation}
                    handleFormValues={(val) => setAcceptCancellation(val)}
                  />
                </Box>
                <Text size="1rem" color={primaryText}>
                  {cancelMessage}
                </Text>
              </Box>
              <AppButton
                overrideHover
                onClick={() => handleCancelRequest()}
                level="authSubmit"
                disabled={!acceptCancellation || submitLoading}
                bgColor="#EC2C2C"
                color="white"
                label={buttonLabel}
                alignSelf="center"
              />
            </Box>
          </Box>
        </SectionContainer>
      </Box>
    </AuthNavWrapper>
  );
  /* eslint-enable camelcase */
};

function mapStateToProps(state) {
  return {
    companyId: state.auth.companyId,
    loading: state.fetchLoader.dataLoading,
    submitLoading: state.fetchLoader.submissionLoading,
    subscriptionData: state.payment.subscriptionData,
    verticalTiers: state.payment.verticalTiers,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchSubscription: fetchCustomerSubscriptionRequest,
    clearSubscription: clearCustomerSubscriptionRequest,
    fetchVerticalTiers: fetchVerticalTiersRequest,
    clearVerticalTiers: clearVerticalTiersRequest,
    updateSubscription: initiateUpdateSubscriptionRequest,
    updatePaymentMethod: initiateUpdatePaymentMethodRequest,
  }, dispatch);
}

ManageSubscriptionPage.propTypes = {
  small: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  }).isRequired,
  authPagesConfig: PropTypes.shape({
    pageBg: PropTypes.string.isRequired,
    bannerBg: PropTypes.string.isRequired,
    altComponentBg: PropTypes.string.isRequired,
    navBorder: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    incrementText: PropTypes.string.isRequired,
    decrementText: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    iconHighlightColor: PropTypes.string.isRequired,
    accountPlaceholder: PropTypes.string.isRequired,
  }),
  customReports: PropTypes.objectOf(PropTypes.any),
  cyclopsConfig: PropTypes.arrayOf(PropTypes.any),
  companyId: PropTypes.number.isRequired,
  fetchSubscription: PropTypes.func.isRequired,
  clearSubscription: PropTypes.func.isRequired,
  fetchVerticalTiers: PropTypes.func.isRequired,
  clearVerticalTiers: PropTypes.func.isRequired,
  updateSubscription: PropTypes.func.isRequired,
  updatePaymentMethod: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  submitLoading: PropTypes.bool.isRequired,
  subscriptionData: PropTypes.shape({
    status: PropTypes.string.isRequired,
    productData: PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      searchLimit: PropTypes.string.isRequired,
      takedowns: PropTypes.string.isRequired,
    }),
    cardData: PropTypes.shape({
      brand: PropTypes.string.isRequired,
      last4: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      expMonth: PropTypes.number.isRequired,
      expYear: PropTypes.number.isRequired,
    }),
    createdAt: PropTypes.number.isRequired,
    currentPeriodEnd: PropTypes.number.isRequired,
    currentPeriodStart: PropTypes.number.isRequired,
  }),
  verticalTiers: PropTypes.arrayOf(PropTypes.shape({
    stripeId: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      defaultPlatforms: PropTypes.string.isRequired,
      delisting: PropTypes.string,
      infringementUpload: PropTypes.string.isRequired,
      manualReview: PropTypes.string.isRequired,
      platformCount: PropTypes.string.isRequired,
      searchLimit: PropTypes.string.isRequired,
      takedowns: PropTypes.string.isRequired,
      uid: PropTypes.string.isRequired,
      verticalUid: PropTypes.string.isRequired,
      order: PropTypes.string.isRequired,
      displayPrice: PropTypes.string.isRequired,
      displayPlatforms: PropTypes.string.isRequired,
    }).isRequired,
    priceId: PropTypes.string.isRequired,
  })),
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withProductAuth(ManageSubscriptionPage),
);
